import { ErrorHandler, NgModule } from '@angular/core';
import { GlobalErrorHandlerService } from '@core/shared/util';
import { AppInsightsFacade } from './services/app-insights.facade';
import { AppInsightsService } from './services/app-insights.service';

@NgModule({
  providers: [
    AppInsightsService,
    AppInsightsFacade,
    {
      provide: GlobalErrorHandlerService,
      useFactory: (errorHandler: ErrorHandler) => {
        return errorHandler instanceof GlobalErrorHandlerService
          ? errorHandler
          : null;
      },
      deps: [ErrorHandler],
    },
  ],
})
export class AppInsightsDataAccessModule {
  // Service initialization
  constructor(private _: AppInsightsService) {}
}
