import { Pagination, PaginationMetadata } from '@mp/shared/data-access';

export function getPaginationParamsAfterDeletion(
  paginationMetadata: PaginationMetadata | undefined,
): Partial<Pagination> {
  if (!paginationMetadata) return { page: 1 };
  const { currentPage, currentRowCount, limit } = paginationMetadata;
  const page = currentRowCount === 1 ? currentPage - 1 : currentPage;
  return { page: page || 1, pageSize: limit };
}
