import { ContactInfo, EnvConfigWithRequiredFields, EnvironmentConfig } from '@mp/shared/data-access';

// eslint-disable-next-line complexity
export const envConfigHasRequiredFields = (configObject: EnvironmentConfig): configObject is EnvConfigWithRequiredFields => {
  const config: EnvironmentConfig = configObject;

  if (!config) {
    return false;
  }

  return (
    config.appTitle != null &&
    config.exportSize != null &&
    config.favoritesListSize != null &&
    config.defaultCategoryType != null &&
    config.userIssuesContactInfo != null &&
    config.theme != null &&
    isContactInfoValid(config.userIssuesContactInfo)
  );
};

function isContactInfoValid(contactInfo: ContactInfo): boolean {
  return contactInfo.emailRecipients != null && contactInfo.emailSubject != null && contactInfo.emailBody != null;
}
