import { PaginationMetadata } from './page-response';

export interface BasicState<T> {
  isLoading: boolean;
  entities?: Array<T>;
  pagination?: PaginationMetadata;
  selected?: T;
}

export function buildInitialBasicState<T>(): BasicState<T> {
  return {
    isLoading: false,
    entities: undefined,
    pagination: undefined,
    selected: undefined,
  };
}
