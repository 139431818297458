import { Injectable } from '@angular/core';

import { AuthService, LocalStorageService } from '@core/shared/data-access';

@Injectable({ providedIn: 'root' })
export class HangfireFacade {
  constructor(private readonly authService: AuthService, private readonly storageService: LocalStorageService) {}

  getHangfireUrl(jobId?: string): string | undefined {
    const accessToken: string = this.authService.getAccessToken();
    const activeOrganisationId: string | null = this.storageService.readString('activeOrganisationId', null);

    if (activeOrganisationId == null) {
      return undefined;
    }

    const link = new URL(self.location.origin);
    link.pathname = jobId ? `/jobs/jobs/details/${jobId}` : '/jobs';
    link.searchParams.set('t', accessToken);
    link.searchParams.set('x-active-organisation', activeOrganisationId);

    return link.toString();
  }
}
