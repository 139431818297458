import { NgModule } from '@angular/core';

import { PrettyPrintListPipeIntl, PrettyPrintListPipeIntlGerman } from './pretty-print-list-intl';
import { ArtikelItemPipe } from './artikel-item.pipe';
import { AutoGrammaticalNumberLabelPipe } from './grammatical-number-label.pipe';
import { GetFromMapPipe } from './get-from-map.pipe';
import { InBracketsPipe } from './in-brackets.pipe';
import { IsContainedInArrayPipe } from './is-contained-in-array.pipe';
import { IsUUIDPipe } from './is-uuid.pipe';
import { JoinListPipe } from './join-list.pipe';
import { MapPipe } from './map.pipe';
import { PrettyPrintListPipe } from './pretty-print-list.pipe';
import { StripHTMLPipe } from './strip-html.pipe';
import { StripQuotesPipe } from './strip-quotes.pipe';

@NgModule({
  declarations: [
    InBracketsPipe,
    JoinListPipe,
    PrettyPrintListPipe,
    StripHTMLPipe,
    StripQuotesPipe,
    AutoGrammaticalNumberLabelPipe,
    ArtikelItemPipe,
    MapPipe,
    IsUUIDPipe,
    GetFromMapPipe,
    IsContainedInArrayPipe
  ],
  exports: [
    InBracketsPipe,
    JoinListPipe,
    PrettyPrintListPipe,
    StripHTMLPipe,
    StripQuotesPipe,
    AutoGrammaticalNumberLabelPipe,
    ArtikelItemPipe,
    MapPipe,
    IsUUIDPipe,
    GetFromMapPipe,
    IsContainedInArrayPipe
  ],
  providers: [
    { provide: PrettyPrintListPipeIntl, useClass: PrettyPrintListPipeIntlGerman }
  ]
})
export class UtilPipesModule {}
