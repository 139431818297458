import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMapTo, shareReplay } from 'rxjs/operators';

import { AuthService } from '@core/shared/data-access';
import { AppInsightsConfig } from '@mp/shared/app-insights/data-access';

import { ConfigLoaderFacade } from './config-loader.facade';
import { EnvironmentConfig } from './models';

@Injectable()
export class AppConfigService {
  envConfig$: Observable<EnvironmentConfig> = this.configLoaderFacade.environmentConfig$;

  appInsightsConfig$: Observable<AppInsightsConfig> =
    // For security reasons app insights config should not be downloaded before user is logged in
    this.authService.userSuccessfullyLoggedIn$.pipe(
      concatMapTo(this.configLoaderFacade.appInsightsConfig$),
      shareReplay(1),
    );

  constructor(private readonly configLoaderFacade: ConfigLoaderFacade, private authService: AuthService) {}
}
