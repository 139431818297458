import { Pagination } from '@mp/shared/data-access';

interface PageChangeEvent {
  pageIndex: number;
  pageSize: number;
}

export function getPageChangePagination({ pageIndex, pageSize }: PageChangeEvent): Pagination {
  return {
    page: pageIndex + 1,
    pageSize,
  };
}
