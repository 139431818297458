import { Action, Store } from '@ngrx/store';

import { QueryParams, SortingDirection, SortingDirectionType } from '@core/shared/util';

// eslint-disable-next-line @typescript-eslint/ban-types
type ObjectType = object;
type SortCriterion<T> = { field: keyof T & string; direction?: SortingDirection | SortingDirectionType };
export type SearchOptions<T extends ObjectType, R extends ObjectType> = {
  baseParams?: QueryParams<T, R>;
  searchFields?: Array<keyof R & string>;
  sortBy?: SortCriterion<R> | Array<SortCriterion<R>>;
};

type LoadActionBuilderPartial<T extends ObjectType> = (payload: {
  queryParams?: QueryParams<T, T | Partial<T>>;
}) => { queryParams?: QueryParams<T, T | Partial<T>> } & Action;

export class BaseFacade<T extends ObjectType> {
  constructor(
    private readonly _store$: Store,
    protected readonly defaultQueryParams?: QueryParams<T, Partial<T>>,
  ) {}

  protected loadAllMatchingSearchTerm<R extends Partial<T>>(
    searchTerm: string,
    loadAllAction: LoadActionBuilderPartial<T>,
    options: SearchOptions<T, R>,
  ): void {
    const queryParams: QueryParams<T, any> = options.baseParams
      ? QueryParams.copy(options.baseParams)
      : QueryParams.build<T>();

    if (searchTerm && options?.searchFields) {
      queryParams.filter(options.searchFields ?? [], '@=*', searchTerm);
    }

    if (options?.sortBy) {
      const sortingCriterias = options.sortBy instanceof Array ? options.sortBy : [options.sortBy];
      sortingCriterias.forEach((criterion) => {
        queryParams.sort(criterion.field, criterion.direction);
      });
    }

    this._store$.dispatch(loadAllAction({ queryParams }));
  }
}
