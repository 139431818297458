import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Applikation } from './applikation';
import { QueryParams } from '@core/shared/util';

@Injectable({ providedIn: 'root' })
export class ApplikationenService {

  applications: Array<Applikation> = [];

  constructor(private readonly http: HttpClient) { }

  getAll(): Observable<Array<Applikation>> {
    const params = QueryParams.build<Applikation>()
      .noPaginationSupported()
      .toHttpParams();

    return this.http.get<Array<Applikation>>('/api/applikationen', { params })
      .pipe(map((applications: Array<Applikation>) => {
        const apps = applications ? this.sortiereApplikationen(applications) : applications;
        this.applications = apps;

        return apps;
      }));
  }

  private sortiereApplikationen(applications: Array<Applikation>): Array<Applikation> {

    // Wenn nicht an allen Einträgen der gleichen parentApplication eine SortOrder gesetzt ist,
    // dann wird die Original-Reihenfolge beibehalten (kompatibel zum früheren Zustand ohne sortOrder):
    if (applications.every(a => a.sortOrder !== undefined && a.sortOrder !== null)) {
      applications.sort((a, b) => a.sortOrder - b.sortOrder);
    }

    for (const app of applications) {
      app.childApplications = this.sortiereApplikationen(app.childApplications);
    }

    return applications;
  }
}
