import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Identifiable } from '@core/shared/data-access';
import { PageResponse } from '../models/page-response';
import { QueryParams } from '@core/shared/util';

// eslint-disable-next-line @typescript-eslint/ban-types
type ObjectType = object;
export class ApiBaseService<T extends ObjectType, TCreate, TUpdate extends Identifiable> {
  protected readonly baseUrl: string;

  constructor(
    protected http: HttpClient,
    url: string
  ) {
    this.baseUrl = (url && !url.startsWith('/')) ? `/${url}` : url;
  }

  getAll<R extends ObjectType = T>(params?: QueryParams<T, R> | HttpParams): Observable<PageResponse<R>> {
    return this.http.get<PageResponse<R>>(
      this.baseUrl,
      { params: params instanceof QueryParams ? params.toHttpParams() : params }
    );
  }

  get<R extends ObjectType = T>(id: number, params?: QueryParams<T, R> | HttpParams): Observable<R> {
    return this.http.get<R>(
      `${this.baseUrl}/${id}`,
      { params: params instanceof QueryParams ? params.toHttpParams() : params }
    );
  }

  create(entity: TCreate): Observable<T> {
    return this.http.post<T>(this.baseUrl, entity);
  }

  update(entity: TUpdate): Observable<T> {
    return this.http.put<T>(`${this.baseUrl}/${entity.id}`, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/${id}`, { observe: 'response' })
      .pipe(map((response: HttpResponse<boolean>) => response.status === 204));
  }
}
