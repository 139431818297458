import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { UploadResult } from './upload-result';

export class BaseUploaderService {
  protected readonly url: string;

  constructor(
    protected readonly http: HttpClient,
    url: string
  ) {
    this.url = (url && !url.startsWith('/')) ? `/${url}` : url;
  }

  protected uploadFile(file: File, lastFileId = ''): Observable<UploadResult> {
    const result$ = new Subject<UploadResult>();
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);

    let uploadUrl = this.url;
    if (lastFileId) {
      uploadUrl += `?lastFileId=${lastFileId}`;
    }

    const req = new HttpRequest('POST', uploadUrl, formData, {
      reportProgress: true,
      responseType: 'text'
    });

    const progress$ = new Subject<number>();

    this.http.request(req).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {

        const percentDone = Math.round((event.loaded * 100) / (event.total ?? 1));
        progress$.next(percentDone);
        result$.next({ progress: percentDone, completed: percentDone === 100, finalFileName: '' });
      } else if (event instanceof HttpResponse) {

        result$.next({ progress: 100, completed: true, finalFileName: event.body as string });
        result$.complete();
      }
    });

    return result$;
  }

  protected uploadFiles(files: Set<File>): { [key: string]: Observable<UploadResult> } {
    const status: { [key: string]: Observable<UploadResult> } = {};

    files.forEach(file => {
      status[file.name] = this.uploadFile(file);
    });

    return status;
  }

}
