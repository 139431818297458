import { Artikel, ArtikelBild, ArtikelItem, BezugsquelleIndustrie, BezugsquelleKlinik, Einheiten, Industrieartikel, Klinikartikel, Preis } from '@mp/medical-shop/artikelsuche/data-access';


export class ArtikelDtoAdapter implements ArtikelItem {
  type: 'Matched' | 'Klinik' | 'Industrie';

  constructor (public artikel: Artikel) {
    this.type = artikel.klinikArtikel && artikel.industrieArtikel ? 'Matched' : artikel.klinikArtikel ? 'Klinik' : 'Industrie';
  }

  get name(): string | undefined {
    return this.artikel?.klinikArtikel?.name ?? this.artikel?.industrieArtikel?.name;
  }

  get industrieBezugsquelle(): BezugsquelleIndustrie | undefined {
    return this.artikel.industrieArtikel?.bezugsquellen[0];
  }

  get klinikBezugsquelle(): BezugsquelleKlinik | undefined {
    return this.artikel.klinikArtikel?.bezugsquellen.find( q => {
      return q.istBevorzugteBezugsquelle === true;
    });
  }

  get packungseinheit(): Einheiten | undefined {
    if( this.type === 'Industrie'){
      return this.industrieBezugsquelle?.packungseinheiten[0];
    }else {
      return this.klinikBezugsquelle?.packungseinheiten.find( e => {
        return e.istStandardBestellmengenEinheit === true;
      });
    }
  }

  get preiseinheit(): Preis | undefined {
    return this.packungseinheit?.preise?.find( p => {
      return p.waehrungsId === 'EUR';
    });
  }

  get preis(): number | undefined {
      return this.preiseinheit?.preis;
  }

  get basismengeneinheit():string | undefined {
    if( this.type === 'Industrie'){
      return this.industrieBezugsquelle?.basismengeneinheit.einheitId;
    }else {
      return this.klinikBezugsquelle?.basismengeneinheit.einheitId;
    }
  }

  get basispackungseinheit(): string | undefined {
    return this.packungseinheit?.einheitId;
  }

  get herstellerName(): string | undefined {
    if(this.type === 'Matched'){
      return this.artikel.klinikArtikel?.herstellerName ?? this.klinikBezugsquelle?.klinikLieferantenName;
    }else if( this.type === 'Klinik'){
      return this.artikel.klinikArtikel?.herstellerName ?? this.klinikBezugsquelle?.klinikLieferantenName;
    }else {
      return this.artikel.industrieArtikel?.herstellerName ?? this.industrieBezugsquelle?.lieferantenName;
    }
  }

  get bezugsquelleName(): string | undefined {
    return this.type === 'Industrie' ? this.industrieBezugsquelle?.lieferantenName : this.klinikBezugsquelle?.klinikLieferantenName;
  }

  get lieferantenArtikelNr(): string | undefined {
    return this.packungseinheit?.lieferantenArtikelNr;
  }

  get lieferantenArtikelBezeichnung(): string | undefined {
    return this.industrieArtikel?.name;
  }

  get klinikArtikelNr(): string | undefined {
    return this.artikel.klinikArtikel?.klinikArtikelNr;
  }

  get bilder(): Array<ArtikelBild> | undefined {
    return this.artikel.industrieArtikel?.bilder;
  }

  get faktor(): string | undefined {
    return this.packungseinheit?.denominator === 1 ?
    this.packungseinheit?.numerator.toString() :
    `${this.packungseinheit?.numerator.toString()} / ${this.packungseinheit?.denominator.toString()}`;
  }

  get klinikArtikel():Klinikartikel | undefined { return this.artikel?.klinikArtikel; }
  get industrieArtikel():Industrieartikel | undefined { return this.artikel.industrieArtikel; }
}
