import { Injectable } from '@angular/core';

import { InsightsEvent, InsightsEventProperties } from '@mp/shared/app-insights/domain';

import { AppInsightsService } from './app-insights.service';

@Injectable()
export class AppInsightsFacade {
  constructor(private appInsights: AppInsightsService) {}

  trackEvent(eventName: InsightsEvent, customProperties?: InsightsEventProperties): void {
    this.appInsights.trackEvent(eventName, customProperties);
  }
}
