import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'inBrackets' })
export class InBracketsPipe implements PipeTransform {

  transform(value?: string | number | boolean, brackets = '()'): string | undefined {
    if (!value) {
      return undefined;
    }

    const [leftBracket, rightBracket] = brackets;
    return `${leftBracket}${value}${rightBracket}`;
  }

}
