import { ModuleWithProviders, NgModule } from '@angular/core';

import { IMAGE_LOADER_CONFIG, ImageLoaderConfig } from './image-loader-config';
import { ImageLoaderService } from './image-loader.service';

@NgModule()
export class ImageLoaderModule {
  static forRoot(config: ImageLoaderConfig): ModuleWithProviders<ImageLoaderModule> {
    return {
      ngModule: ImageLoaderModule,
      providers: [
        ImageLoaderService,
        { provide: IMAGE_LOADER_CONFIG, useValue: config }
      ]
    };
  }
}