import { Pipe, PipeTransform } from '@angular/core';

import { Artikel } from '@mp/medical-shop/artikelsuche/data-access';
import { ArtikelDtoAdapter } from '@core/shared/data-access';


@Pipe({ name: 'artikelItem' })
export class ArtikelItemPipe implements PipeTransform {
  transform(artikel: Artikel): Artikel {
    return new ArtikelDtoAdapter(artikel as Artikel);
  }
}
