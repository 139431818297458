export interface Recht {
  id: number;
  resource: Resource | Resources;
  action: Action | Actions;
}

export interface RechtFilter {
  resource: Resource | Resources;
  action: Action | Actions;
}

export type Resource = `${Resources}`;
export enum Resources {
  Anforderungen = 'Anforderungen',
  AlleAnforderungen = 'AlleAnforderungen',
  EigeneAnforderungen = 'EigeneAnforderungen',
  ArticleSearchFileExport = 'ArticleSearchFileExport',
  Article = 'Artikel',
  ArticleExport = 'ArtikelExport',
  ArticleIndex = 'ArtikelIndexierung',
  ArticleManufacturer = 'ArtikelHersteller',
  ArticleMultiSelect = 'Artikelmehrauswahl',
  BarcodeProtokollEintrag = 'BarcodeProtokollEintrag',
  Belegmanager = 'Belegmanager',
  Carts = 'Warenkoerbe',
  ClinicCatalogFilter = 'Hauskatalogfilter',
  ClinicCatalogs = 'KlinikKataloge',
  ClinicSuppliers = 'Kliniklieferanten',
  ConditionOrganizations = 'Konditionen Organisationszuordnungen',
  ContentManagementArticle = 'ContentManagementArtikel',
  ContentManagementFavoritesLists = 'Favoritenlisten',
  ContractManagementConditions = 'Vertragsmanagement - Konditionen',
  ContractManagementContracts = 'Vertragsmanagement - Verträge',
  ExpertSearch = 'Expertensuche',
  Feedback = 'Feedback',
  FileUploadCM = 'DateiUploadCM',
  GlobalArticle = 'Globalartikel',
  GlobalArticleDataSource = 'Globalartikel Datenquellen',
  GoodsOutgoing = 'Warenausgang',
  GoodsReceipt = 'Wareneingang',
  HangfireDashboard = 'Hangfire Dashboard',
  HelpCenter = 'HelpCenter',
  ImmediatePass = 'Direktfreigabe',
  IndustryCatalogs = 'IndustrieKataloge',
  Inventur = 'Inventur',
  JobsDashboard = 'JobsDashboard',
  Kommissionierung = 'Kommissionierung',
  Kostenstellen = 'Kostenstellen',
  Licenses = 'Lizenzen',
  MasterData = 'Stammdaten',
  Matcher = 'Matcher',
  Materials = 'Materialien',
  MedicalShopWidget = 'medical.shop - Schnelleinstieg Widget',
  MultiOrganizationNews = 'Organisationsübergreifende News',
  MyOrganisationWidget = 'Meine Organisation - Widget',
  NewContent = 'NeuerContent',
  News = 'News',
  OpDocumentation = 'OpDokumentation',
  Organisations = 'Organisationen',
  OrganisationAddresses = 'Adressen',
  OrganisationHierarchies = 'OrganisationsHierarchien',
  OrganisationLicenses = 'OrganisationLizenzen',
  PendingRequisitionsWidget = 'Belege - offene Anforderungen Widget',
  Permissions = 'Rechte',
  PlatformCatalogs = 'PlattformKataloge',
  PlatformCatalogReindex = 'Alle Plattformkataloge reindexieren',
  PlatformPackagingUnits = 'PlatformPackagingUnits',
  PlatformPackagingMappings = 'PlatformPackagingMappings',
  PlatformSuppliers = 'Plattformlieferanten',
  RequisitionsHistoryWidget = 'Belege - historische Anforderungen Widget',
  Roles = 'Rollen',
  RolesPermissions = 'Rollen/Rechte',
  RolesUser = 'Rollen/Benutzer',
  Schrankversorgung = 'Schrankversorgung',
  StandardSet = 'StandardSet',
  Suppliers = 'Lieferanten',
  TermsAndConditions = 'Rahmenvertragsdaten',
  Users = 'Benutzer',
  UserWithMatchingKostenstellen = 'Benutzer gleicher Kostenstellen',
}

export type Action = `${Actions}`;
export enum Actions {
  Config = 'Konfigurieren',
  Delete = 'Loeschen',
  Edit = 'Bearbeiten',
  Export = 'Exportieren',
  Pass = 'Freigeben',
  Read = 'Lesen',
  Use = 'Benutzen',
  Write = 'Schreiben',
  Show = 'Anzeigen',
}

export type Rolle =
  | 'Belegmanager'
  | 'Content-Admin'
  | 'Content-Pfleger'
  | 'Einkauf'
  | 'Inventur'
  | 'Kommissionierung'
  | 'Lieferanten-Manager'
  | 'OP-Dokumentation'
  | 'OP Standard-Set'
  | 'Organisationsbesitzer'
  | 'Pflege'
  | 'Plattform-Admin'
  | 'Plattform-Benutzer'
  | 'Warenausgang'
  | 'Wareneingang';
