import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

// TODO: Fix this circular dependency
import { LocalStorageService } from '@core/shared/data-access';


@Injectable()
export class ActiveOrganisationInterceptor implements HttpInterceptor {

  constructor(private readonly storageService: LocalStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.storageService.isAvailable()) {
      throw new Error('"activeOrganisationId" could not be read because localstorage is deactivated for this site.');
    }

    const activeOrganisationId: number | null = this.storageService.read('activeOrganisationId', null);

    return next.handle(
      activeOrganisationId ?
        this.attachHeadersToRequest(req, activeOrganisationId) :
        this.simplyForwardRequest(req)
    );
  }

  private attachHeadersToRequest(request: HttpRequest<any>, activeOrganisationId: number): HttpRequest<any> {
    const requestWithHeader = request.clone({
      setHeaders: { 'x-active-organisation': activeOrganisationId.toString() }
    });

    return requestWithHeader;
  }

  private simplyForwardRequest(request: HttpRequest<any>): HttpRequest<any> {
    return request;
  }

}
