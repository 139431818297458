import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { IMAGE_LOADER_CONFIG, ImageLoaderConfig } from './image-loader-config';
import { BaseUploaderService } from '../base/base-uploader.service';
import { ImageTypeUtils as Utils } from './image-type-utils';

@Injectable()
export class ImageLoaderService extends BaseUploaderService {

  constructor(
    http: HttpClient,
    @Inject(IMAGE_LOADER_CONFIG) config: ImageLoaderConfig
  ) {
    super(http, config.uploadUrl);
  }

  uploadImage(filehandle: File, allowedTypes: Array<string> = Utils.DEFAULT_ALLOWED_TYPES): Observable<string> {
    if (allowedTypes) {
      if (!Utils.isOfGivenImageTypes(filehandle.type, allowedTypes)) {
        return throwError(
          new Utils.ImageTypeError(
            Utils.ImageTypeError.IMAGE_TYPE_NOT_ALLOWED(filehandle.type, allowedTypes)
          )
        );
      }
    } else {
      if (!Utils.isImageType(filehandle.type)) {
        return throwError(
          new Utils.ImageTypeError(
            Utils.ImageTypeError.FILE_TYPE_IS_NO_IMAGE_TYPE(filehandle.type)
          )
        );
      }
    }

    return this.uploadFile(filehandle).pipe(
      // TODO: No error-handling yet!
      filter(uploadResult => uploadResult.completed),
      map(uploadResult => uploadResult.finalFileName)
    );
  }

  downloadImage(fileId: string): Observable<Blob> {
    const fileUrl = `${this.url}/${fileId}`;
    const headers = new HttpHeaders();

    headers.append('Content-Type', 'image/*');
    headers.append('Accept', 'image/*');

    return this.http.get(fileUrl, { headers, responseType: 'blob' });
  }
}
