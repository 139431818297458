import { Overwrite } from 'utility-types';

export namespace ImageTypeUtils {

  export type WithImageFile<T extends { bild: string }> = Overwrite<T, { bild: File }>;

  export enum FileTypes {
    JPEG = 'image/jpeg',
    GIF = 'image/gif',
    PNG = 'image/png'
  }

  export const DEFAULT_ALLOWED_TYPES = [FileTypes.JPEG, FileTypes.PNG];

  export function isDefaultImageType(fileType: string): boolean {
    return isOfGivenImageTypes(fileType, ImageTypeUtils.DEFAULT_ALLOWED_TYPES);
  }

  export function isOfGivenImageTypes(filetype: string, allowedTypes: Array<string>): boolean {
    return allowedTypes.includes(filetype);
  }

  export function isImageType(filetype: string): boolean {
    return filetype.split('/')[0] === 'image';
  }

  export class ImageTypeError extends Error {

    static IMAGE_TYPE_NOT_ALLOWED = (type: string, allowedTypes: Array<string>) =>
    `The given files type "${type}" is not one of the allowed file types (${String(allowedTypes)}).`;

    static FILE_TYPE_IS_NO_IMAGE_TYPE = (type: string) =>
    `The given files type "${type}" is not an image-type!`;

    constructor(message: string) {
      super(message);

      Object.setPrototypeOf(this, ImageTypeError.prototype);

      this.name = 'ImageTypeIncompatibility';
    }
  }

}
