import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/ban-types
type ObjectType = object;
export function stripFields<T extends ObjectType, Field extends keyof T & string>(
  fieldsToOmit: Array<Field>
): (source$: Observable<T>) => Observable<Omit<T, Field>> {
  return (source$: Observable<T>) => source$.pipe(
    map(entity => {
      const entityWithoutFields: Partial<T> = { ...entity };

      fieldsToOmit.forEach(field => {
        if (field in entityWithoutFields) {
          delete entityWithoutFields[field];
        }
      });

      return entityWithoutFields as Omit<T, Field>;
    })
  );
}
