import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ROUTER_FEATURE_KEY, RouterState } from './router-reducer';

const getRouterState = createFeatureSelector<RouterState>(ROUTER_FEATURE_KEY);

const STATE = createSelector(
  getRouterState,
  state => state.state
);

const FROM_PARAM_MAP = createSelector(
  getRouterState,
  (state: RouterState, props: string) => state.state.paramMap.get(props)
);

const FROM_QUERY_PARAM_MAP = createSelector(
  getRouterState,
  (state: RouterState, props: string) => state.state.queryParamMap.get(props)
);

const IS_SYSTEM = createSelector(
  getRouterState,
  state => state.state.isSystemRoute
);

const ENTITY_ID_FROM_ROUTE = () => createSelector(
  FROM_PARAM_MAP as any,
  (routerParamId: string) => ({ routerParamId: parseInt(routerParamId) })
);

export const routerSelectors = {
  STATE,
  FROM_PARAM_MAP,
  FROM_QUERY_PARAM_MAP,
  IS_SYSTEM,
  ENTITY_ID_FROM_ROUTE
};
